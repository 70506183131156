import React from 'react';
import Layout from '../components/Layout';
import Breadcrums from "../components/Breadcrums";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/servicios_asistenciales_complementarios.jpeg)`,
                        }}
                    >
                        <h1>Contabilidad Electrónica</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <Breadcrums name="Contabilidad Electrónica"/>
                        <div className="card">
                            <div className="card__content">
                                <p className="services_section_1__paragraph">
                                    La contabilidad Electrónica se refiere al envío mensual por medio
                                    del buzón tributario de los archivos solicitados por la autoridad.
                                </p>
                                <div>
                                    <ul style={{color: '#000000'}}>
                                        <li>Balanzas de comprobacion</li>
                                        <li>Catalogo de cuentas</li>
                                        <li>Pólizas del periodo</li>
                                        <li>Auxiliares de cuenta o subcuenta</li>
                                        <li>Auxiliar de folios de los comprobantes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Service_one;
